import { classNames } from "cfg-base";
import React, { useCallback, useEffect } from "react";
import * as ReactDOM from "react-dom";
import { AnchorButton } from "../atoms/AnchorButton";
import { PanoramaViewer } from "../atoms/PanoramaViewer";
import { Portal } from "../atoms/Portal";
import { useIFrameModalPositionAdjuster } from "../hooks/useIFrameModalPositionAdjuster";

interface Props {
	t: Translator;
	closeHandler?: (event: React.MouseEvent<HTMLElement>) => void;
	title?: string;
}

interface ModalPortalProps extends Props {
	closeHandler: (event: React.MouseEvent<HTMLElement>) => void;
	showCloseButton?: boolean;
	title: string;
	fullsize?: boolean;
	fitContent?: boolean;
	hidden?: boolean;
}

export const ModalPortal: React.FC<ModalPortalProps> = (props) => {
	const { closeHandler } = props;

	const cbCloseHandler = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			if (event.currentTarget !== event.target) {
				return;
			}
			event.preventDefault();
			closeHandler(event);
		},
		[closeHandler]
	);
	const { t, title } = props;

	return (
		<Portal id="react-modal">
			<Modal
				closeHandler={cbCloseHandler}
				showCloseButton={props.showCloseButton}
				title={title}
				t={t}
				fullsize={props.fullsize}
				fitContent={props.fitContent}
				hidden={props.hidden}
			>
				{props.children}
			</Modal>
		</Portal>
	);
};

// Complete modal that handles state changes like close

interface BasicModalProps extends Props {
	children: React.ReactNode;
	className?: string;
	fullsize?: boolean;
	noPadding?: boolean;
	removeOnClose?: boolean;
}

interface BasicModalState {
	elementId: string;
	hidden: boolean;
}

// tslint:disable-next-line:max-classes-per-file
export class BasicModal extends React.Component<BasicModalProps, BasicModalState> {
	state = {
		elementId: "",
		hidden: false,
	};

	handleClose = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget !== event.target) {
			return;
		}

		event.preventDefault();

		if (this.props.removeOnClose) {
			const element = document.getElementById(this.state.elementId);
			if (element) {
				ReactDOM.unmountComponentAtNode(element);
			}
		} else {
			this.setState({ hidden: true });
		}
		this.props.closeHandler && this.props.closeHandler(event);
	};

	render() {
		return (
			<Modal
				className={this.props.className}
				closeHandler={this.handleClose}
				fullsize={this.props.fullsize}
				hidden={this.state.hidden}
				noPadding={this.props.noPadding}
				t={this.props.t}
				title={this.props.title}
			>
				{this.props.children}
			</Modal>
		);
	}
}

// Modal is just the html-element, use this if you want to implement custom state.

interface ModalProps extends Props {
	children: React.ReactNode;
	className?: string;
	closeHandler: (event: React.MouseEvent<HTMLElement>) => void;
	showCloseButton?: boolean;
	fullsize?: boolean;
	fitContent?: boolean;
	hidden?: boolean;
	noPadding?: boolean;
	t: Translator;
}

export const Modal: React.FC<ModalProps> = (props) => {
	const { className, closeHandler, showCloseButton, fullsize, hidden, noPadding, t, fitContent } = props;
	const closeButtonRef = React.useRef<HTMLButtonElement>(null);
	const { setDialogHeight, setScrollPosition, setViewPortHeight } =
		useIFrameModalPositionAdjuster();

	useEffect(() => {
		const isInIframe = window !== window.parent ? true : false;
		if (!isInIframe) return;

		window.addEventListener(
			"message",
			function (event) {
				const scrollPosition: number = event.data.recalculateDialogPosition;
				const viewPortHeight: number = event.data.viewPortHeight;
				const boxes = document.querySelectorAll<HTMLElement>(".modal-content");
				const myObserver = new (window as any).ResizeObserver((entries: any) => {
					for (let entry of entries) setDialogHeight(entry.contentRect.height);
				});
				boxes.forEach((box) => {
					myObserver.observe(box);
				});

				setViewPortHeight(viewPortHeight);
				setScrollPosition(scrollPosition);
			},
			false
		);
	}, [setDialogHeight, setScrollPosition, setViewPortHeight]);

	useEffect(() => {
		function handleKeyDown(event: KeyboardEvent) {
			const code = event.keyCode || event.charCode;
			switch (code) {
				case 27:
					if (closeButtonRef.current !== null) {
						closeButtonRef.current.click();
					}
					break;
				default:
					break;
			}
		}

		document.addEventListener("keydown", handleKeyDown);

		return function cleanup() {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	const modalClasses = classNames(["modal", !hidden && "is-visible", className && className]);
	const containerClasses = classNames([
		"container",
		"mod-modal",
		noPadding && "no-padding",
		"ut-flex",
		"ut-justify-content-center",
		"ut-align-items-center",
	]);
	const columnClasses = classNames([
		"col",
		"mod-modal",
		!fitContent && "is-12",
		fitContent && "ut-max-width-fit-content",
		!fitContent && !fullsize && noPadding && "is-9-on-sm is-6-on-md",
		!fitContent && !fullsize && !noPadding && "is-10-on-sm is-8-on-md",
	]);

	let headerAndCloseButton = showCloseButton === false ? '' : (
		<button className="modal-close" onClick={closeHandler} ref={closeButtonRef}>
			<i className="svg-icon mod-cross" onClick={closeHandler} />
		</button>
	);

	if (!noPadding) {
		headerAndCloseButton = (
			<div className="modal-header">
				{props.title ? t(props.title) : ""}
				{headerAndCloseButton}
			</div>
		);
	}

	return (
		<div className={modalClasses}>
			<div className={containerClasses} onClick={closeHandler}>
				<div className={columnClasses}>
					<div className="modal-content">
						{headerAndCloseButton}
						<div className="modal-body">{props.children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

interface MediaModalProps extends ModalPortalProps {
	srcUrl: string;
	type: string;
	target?: string | "_blank" | "_self" | "_parent" | "_top";
}

export const MediaModal: React.FC<MediaModalProps> = (props) => {
	const { closeHandler, fullsize, srcUrl, t, title, type, target } = props;

	return (
		<ModalPortal closeHandler={closeHandler} t={t} title={title} fullsize={fullsize}>
			{type === "video" && (
				<div className="ut-embed-responsive mod-16by9">
					<video muted loop controls>
						<source src={srcUrl} type="video/mp4" />
					</video>
				</div>
			)}
			{type === "externalVideo" && (
				<div className="ut-embed-responsive mod-16by9">
					<iframe
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen={true}
						className="embed-responsive-item"
						frameBorder="0"
						src={srcUrl}
						title="Video"
					/>
				</div>
			)}

			{type === "image" && <img className="ut-responsive-image" src={srcUrl} alt="" />}
			{type === "pdf" && (
				<AnchorButton
					color="blue"
					href={srcUrl}
					icon="mod-pdf"
					t={t}
					text="button.download_pdf"
					target={target ?? "_blank"}
				/>
			)}
			{type === "vr-image" && (
				<div className="ut-embed-responsive mod-16by9">
					<div className="embed-responsive-item">
						<PanoramaViewer
							canvasDimension={{ height: 1080, width: 1920 }}
							src={srcUrl}
						/>
					</div>
				</div>
			)}

			{type === "vimeo" && (
				<div className="ut-embed-responsive mod-16by9">
					<iframe
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen={true}
						className="embed-responsive-item"
						frameBorder="no"
						src={srcUrl}
						title="Vimeo"
					/>
				</div>
			)}
		</ModalPortal>
	);
};

// showModal lets you show your modal in the correct place on the page
export function showModal(modal: JSX.Element, elementId: string) {
	let portal = document.getElementById("react-modal");
	if (!portal) {
		portal = document.createElement("div");
		portal.id = "react-modal";
		document.body.appendChild(portal);
	}

	let element = document.getElementById(elementId);
	if (!element) {
		element = document.createElement("div");
		element.id = elementId;
		portal.appendChild(element);
	}

	const component: any = ReactDOM.render(modal, element);

	if (component instanceof React.Component) {
		component.setState({ hidden: false, elementId });
	}
}
